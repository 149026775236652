import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { checkLogin, getIsLogged, logout } from './Helpers/Util';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  constructor(props) {
    super(props);

    this.state = { logginStatus: true, loader: "hidder" };
  }

  componentDidMount() {
    let events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    for (var i in events) {
      window.addEventListener(events[i], this.resetTimeout);
    }
  }

  resetTimeout = () => {
    if (!checkLogin() && getIsLogged()) {
      this.setState({ loader: "show" });
      setTimeout(() => {
        logout();
        this.setState({ loader: "hidder" });
      }, 3000);
    }
  }

  render() {
    const { loader } = this.state;
    
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {!getIsLogged() && <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />}
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
        {/*page loader*/}
        <div class={"overly-loader " + loader}>
          <div class="loader-img">
            <center>
              <img src="/loading.gif"></img>
              <br />
              <p align="center">{"Session expired logging Out.."}</p>
            </center>

          </div>
        </div>
        {/*page loader*/}
      </HashRouter>
    );
  }
}

export default App;
