import jwt_decode from 'jwt-decode';

export const setToken = (token) => {
  localStorage.setItem('session-token', token);
}

export const setIsLogged = (status) => {
  localStorage.setItem('is-logged', status);
}

const setIsPasswordReset = (status) => {
  localStorage.setItem('is-pw-reset', status);
}

const setIsDepartmentSelect = (status) => {
  localStorage.setItem('is-dep-select', status);
}

export const setUserDetails = (data) => {
  let userDetails = {
    id: data.id,
    batch_id: data.batch_id,
    district: data.district,
    z_score: data.z_score,
    department_id: data.department_id,
    department: data.department,
    index_number: data.index_number,
    title: data.title,
    first_name: data.first_name,
    last_name: data.last_name,
    full_name: data.name,
    email: data.email,
    contact: data.contact,
    nic: data.nic,
    address_line_1: data.address_LN_1,
    address_line_2: data.address_LN_2,
    address_line_3: data.address_LN_3,
    profile_picture: data.profile_picture_path,
    signature_picture: data.signature_image_path,
    telephoneHome: data.TelephoneHome,
    internshipPoints: data.available_internshipPoint,
    internshipAvailability: data.isCurrentlyOnAinternship,
    course_id: data.Course_id
  }
  localStorage.setItem('user-details', JSON.stringify(userDetails));
}

export const setLoginOpts = (data) => {
  let LoginOpts = {
    is_password_rest: data.IsPasswordReset,
    is_department_select: data.IsDepartmentSelect,
    last_login_time: data.LastLoginTime,
    token: data.token
  }

  setIsPasswordReset(data.IsPasswordReset);
  setIsDepartmentSelect(data.IsDepartmentSelect);

  localStorage.setItem('login-options', JSON.stringify(LoginOpts));
}

export const checkInternship = () => {
  if(getUser() != null && getUser().internshipAvailability && (getUser().internshipPoints > 0))
    return true

  return false;
}

// export const setUserRoles = (data) => {
//     localStorage.setItem('user-roles', JSON.stringify(data));
// }
//
// export const setActiveRole = (data) => {
//     data.forEach(element => {
//         if (element.is_default === 1) {
//             localStorage.setItem('active-role', JSON.stringify(element));
//         }
//     });
// }
//
// export const switchRole = (roleId) => {
//     let storage = localStorage.getItem('user-roles');
//     if (storage != null) {
//         let userRoles = JSON.parse(localStorage.getItem('user-roles'));
//         userRoles.forEach(element => {
//             if (element.role_id === roleId) {
//                 localStorage.setItem('active-role', JSON.stringify(element));
//             }
//         });
//     }
//     return true;
// }
//
// export const checkPermission = (key) => {
//     let storage = localStorage.getItem('active-role');
//     if (storage != null) {
//         let activeRole = JSON.parse(localStorage.getItem('active-role'));
//         let privilages = activeRole.role_privileges;
//
//         return privilages[key] ? privilages[key] : false;
//     } else {
//         return false;
//     }
// }

// export const checkUserPrivilege = (privilleges, key) => {
//   return privilleges.includes(key);
// }

export const getUser = () => {
  return JSON.parse(localStorage.getItem('user-details'));
}

// export const getRole = () => {
//     return JSON.parse(localStorage.getItem('active-role'));
// }
//
// export const getUserRoles = () => {
//     return JSON.parse(localStorage.getItem('user-roles'));
// }

export const getIsLogged = () => {
  return localStorage.getItem('is-logged') || false;
}

export const getIsPasswordReset = () => {
  return localStorage.getItem('is-pw-reset') !== '0';
}

export const getIsDepartmentSelect = () => {
  return localStorage.getItem('is-dep-select') !== '0';
}

export const getLoginOpts = () => {
  return localStorage.getItem('login-options') || false;
}

export const checkLogin = () => {
  var jwt = window.localStorage.getItem('session-token');
  if (jwt && getIsLogged()) {
    var decoded = jwt_decode(jwt);
    if (Date.now() / 1000 > decoded.exp) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export const logout = () => {
  if (getIsLogged()) {
    localStorage.clear();
    window.location.reload();
  }
}
